<template>
  <main class="plans">
    <b-container>
      <b-row v-if="service.plans&&service.plans.length>0">
        <b-col cols="12" md="7">
          <h2 class="plans__title">{{ $t("heading.bundls") }}</h2>
          <p class="plans__text">
            {{ $t("heading.bundles_description") }}
          </p>
        </b-col>
        <b-col cols="12" md="5" class="align-self-center">
          <b-img fluid :src="require('@/assets/imgs/illustrations/plans/plan.svg')"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mx-auto"
          :class="{ 'p-0': plans.length > 3 }"
          cols="12"
          :md="plans.length > 3 ? 2 : 4"
          v-for="(plan, index) in plans"
          :key="index"
        >
          <Plan :plan="plan" :plans="plans" />
        </b-col>
        <b-col cols="12" md="6" class="mx-auto text-center">
          <Button
            @click="toPlansPage"
            class="button button--show-more"
            :text="$t('button.show_all')"
          />
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Plans",
  components: {
    Plan: () => import("./components/Plan.vue")
  },

  computed: {
    ...mapGetters(["GlobalServices"]),
    service() {
      if (this.GlobalServices.length > 0) {
        return this.GlobalServices.find(el => el.id == this.$route.params.id);
      } else {
        return {};
      }
    },
    plans() {
      return this.service.plans.map((el, index) => {
        return {
          ...el,
          last_plan: this.service.plans.length - 1 == index ? true : false
        };
      });
    }
  },
  methods: {
    toPlansPage() {
      this.$router.push(`/plans/planDetail?service_id=${this.service.id}`);
    }
  }
};
</script>
